.open-positions-banner, .employees-about-us-banner {
    position: relative;

    h1 {
        color: $white;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        @include vertical-center;
    }
}

.positions-page, .employees-about-us-page {
    @include xy-gutters($gutter-type: margin, $gutter-position: top);

    section {
        &.cities, &.positions, &.tags {
            border: 1px solid $box-border-color;

            > div {
                min-height: 100%;
            }

            .checkbox-list {
                max-height: 200px;
                overflow-y: auto;
                transition: box-shadow 200ms;

                &.has-scrollbar {
                    &:not(.scrolled) {
                        box-shadow: inset 0px -11px 8px -10px $box-shadow-color;
                    }
                    &.scrolled {
                        box-shadow: inset 0px -11px 8px -10px $box-shadow-color, inset 0px 11px 8px -10px $box-shadow-color;
                    }
                    &.scrollend {
                        box-shadow: inset 0px 11px 8px -10px $box-shadow-color;
                    }
                }

                label {
                    margin-bottom: $form-spacing / 2;
                    position: relative;

                    input[type="checkbox"] {
                        margin-bottom: 0;
                        vertical-align: middle;
                    }

                    &.disabled:after {
                        content: " ";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(255,255,255,0.5);
                    }
                }
            }

            strong {
                display: block;
            }
        }
    }

    .positions-list {
        .position-row {
            a {
                h2 {
                    font-size: 2rem;
                    font-weight: bold;
                    font-family: inherit;
                    color: $black;
                }
            }

            .details-link {
                color: $marso-red;
                font-weight: bold;
            }
        }
    }

    .positions-list, .employees-list {
        @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);

        .employee-thumb {
            .img-placeholder {
                border-radius: 10%;
            }
        }
    }
}

.maps {
    position: relative;

    #map {
        height: 400px;
        background-color: $light-gray;
        .loading.icon {
            font-size: 5rem;
            position: absolute;
            top: calc(50% - 5rem);
            left: calc(50% - 5rem / 2);
        }
    }
}

.position-page, .employee-about-us-page {
    .position, .employee {
        h1 {
            @each $size, $headers in $header-styles {
                @include breakpoint($size) {
                    font-size: rem-calc(map-get(map-get($headers, 'h1'), font-size) / 1.5);
                }
            }
        }

        .right-border {
            border-right: 0.2rem solid $medium-gray;
        }

        .bottom-border {
            border-bottom: 0.2rem solid $medium-gray;
        }

        .image-gallery, .responsive-embed {
            @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);
            @include clearfix;
        }
    }

    .employee {
        .image-gallery {
            width: 100%;
            float: right;
            text-align: center;

            @include breakpoint(map-get($breakpoints, 'medium')) {
                max-width: 50%;
            }

            a {
                &:not(:first-child) {
                    display: inline-block;
                    width: 20%;
                }

                .img-placeholder {
                    @include xy-gutters($gutter-type: margin, $gutter-position: top bottom left right);
                }
            }
        }
    }

    .employees-about-us-box {
        .employee-name, .employee-position {
            color: $marso-red;
            display: block;
        }

        .avatar {
            float: left;
            border-radius: 50%;
            width: 100%;
            max-width: 30%;
            border: 0.3rem solid $medium-gray;
            overflow: hidden;

            @include xy-gutters($gutter-type: margin, $gutter-position: top bottom left right);
        }
    }

    h1, h2 {
        @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);

        @each $size, $headers in $header-styles {
            @include breakpoint($size) {
                font-size: rem-calc(map-get(map-get($headers, 'h2'), font-size) / 1.5);
            }
        }
    }

    .tags {
        @include xy-gutters($gutter-type: padding, $gutter-position: bottom);
        @include xy-gutters($gutter-type: margin, $gutter-position: bottom);
    }
}
