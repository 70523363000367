nav.white-top {
    background-color: $white;
    a {
        color: $black;
        font-size: $body-font-size * 0.5;
        font-weight: normal;
        line-height: $body-font-size;

        &:hover {
            color: $marso-red;
        }
    }
}

.sticky-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    .top-bar-container {
        background-color: $topbar-background;
        max-width: 100% !important;
        margin-top: 0px !important;

        @include breakpoint(map-get($breakpoints, 'large')) {
            nav.white-top {
                height: 3rem;
                transition: height 300ms ease;
            }

            &.is-stuck {
                nav.white-top {
                    height: 0 !important;
                }
            }
        }

        .top-bar {
            min-height: 58px;
            @include xy-grid-container;

            .logo {
                width: 24rem;
                max-width: 100%;
                max-height: 100%;
            }

            .menu {
                li {
                    a {
                        background-color: transparent;
                        display: inline-block;
                        color: $black;
                        font-size: $body-font-size * 0.75;
                        font-weight: normal;

                        &:hover {
                            color: $marso-red;
                        }
                    }

                    &.active {
                        a {
                            color: $marso-red;
                        }
                    }

                    &:after {
                        color: #808080;
                        content: "•";
                    }

                    &:last-child {
                        &:after {
                            content: "";
                        }
                    }
                }
            }

            #mobile-main-menu-toggle {
                font-size: 3rem;
                margin: 0;
                padding: 0.5rem;
                background-color: transparent;
                color: $black;
            }

            .top-bar-left, .top-bar-right {
                flex: 0 1 auto;
            }

            .top-bar-left {
                max-width: 50%;
                @include breakpoint(map-get($breakpoints, 'medium')) {
                    max-width: auto;
                }
            }
        }
    }
}

#mobile-main-menu {
    li {
        width: 100%;
        @include xy-gutters($gutter-type: padding, $gutter-position: top bottom);

        a {
            color: $black;
        }
    }
}

/* main menu is floated, so add margin to main content */
#main {
    margin-top: 88px;
}

footer {
    background-color: #333;
    color: $white;
    line-height: 5rem;
    font-size: $body-font-size * 0.75;
    font-weight: normal;

    .menu {
        li {
            a, span {
                cursor: pointer;
                color: $white;
                line-height: 5rem;
                padding: 0 nth($menu-items-padding, 2);

                &:hover {
                    color: $marso-red;
                }
            }
        }
    }
}
