$breakpoint-classes: (small medium large xlarge xxlarge) !default;
$global-font-size: 62.5% !default;
// $breakpoints: (
//     small: 0,
//     medium: 640px,
//     large: 1024px,
//     xlarge: 1200px,
//     xxlarge: 1440px,
//     xxxlarge: 1600px,
//     ultimate: 1760px,
// );
$marso-red: #dc2f33;
$body-font-family: 'Poppins', sans-serif;
$header-font-family: 'Abril Fatface', cursive;
$body-font-size: 2rem;
$global-weight-normal: lighter;
$header-styles: (
  small: (
    'h1': ('font-size': 35),
    'h2': ('font-size': 30),
    'h3': ('font-size': 25),
    'h4': ('font-size': 20),
    'h5': ('font-size': 15),
    'h6': ('font-size': 10),
  ),
  medium: (
    'h1': ('font-size': 50),
    'h2': ('font-size': 40),
    'h3': ('font-size': 30),
    'h4': ('font-size': 25),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
  large: (
    'h1': ('font-size': 60),
    'h2': ('font-size': 40),
    'h3': ('font-size': 30),
    'h4': ('font-size': 25),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  )
) !default;
$label-color: #666;
$anchor-color: $marso-red;

$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagrammagenta: #c32aa3;
$linkedin: #007bb5;

$box-border-color: #d6d6d6;
$box-shadow-color: #d6d6d6;

$card-border: none;
$card-divider-background: transparent;

$pagination-item-background-current: $marso-red;
$pagination-item-background-hover: darken($marso-red, 10%);
$pagination-item-color-current: #fff;

$tag-color: #d6d6d6;
