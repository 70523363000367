.img-placeholder {
    background-color: #f6f6f6;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    img {
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity 1s linear;

        &.loaded {
            opacity: 1;
        }

        &.img-small {
            /* this is for proper width and height calculation */
            position: relative;
            filter: blur(50px);
            /* this is needed so Safari keeps sharp edges */
            transform: scale(1);
        }

        &.img-large {
            position: absolute;
        }
    }
}
