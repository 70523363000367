#index-slider-banner {
    h2 {
        color: $white;
        width: 100%;
        text-shadow: 0.1rem 0.1rem 0.2rem $black;
    }

    .orbit-slide {
        position: relative;
        display: block;

        .orbit-figure {
            position: relative;
        }

        .slide-content-wrapper {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            align-content: center;
            align-items: center;

            .slide-content {
                position: relative;
                width: 80%;
                max-width: $global-width;
                background-color: $white;
                max-height: 15rem;
                height: 15rem;

                @include xy-grid-container;

                .content-container {
                    padding: 1rem 1rem 1rem 25%;
                    overflow: hidden;

                    .grid-x {
                        height: 100%;

                        .title {
                            color: $marso-red;
                            font-weight: normal;
                        }

                        .content {
                            font-style: italic;
                        }
                    }
                }

                .image {
                    position: absolute;
                    width: 180px;
                    height: 180px;
                    top: -10%;
                    left: 3%;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 0.5rem solid $white;
                }

                .orbit-previous, .orbit-next {
                    height: 100%;
                    width: 60px;
                    cursor: pointer;
                    background-color: transparent;

                    img {
                        width: 80%;
                        height: 80%;
                        position: absolute;
                        z-index: 1;
                        top: 10%;
                    }

                    &:before {
                        width: 0;
                        height: 0;
                        border-style: solid;
                        display: block;
                        content: " ";
                        position: absolute;
                        top: 0;
                        z-index: 0;
                    }
                }

                .orbit-previous {
                    left: -6rem;
                    border-right: 3.3rem solid $white;

                    img {
                        left: 2rem;
                    }

                    &:before {
                        border-width: 7.5rem 2.7rem 7.5rem 0;
                        border-color: transparent $white transparent transparent;
                        left: 0;
                    }
                }

                .orbit-next {
                    right: -6rem;
                    border-left: 3.3rem solid $white;

                    img {
                        right: 2rem;
                    }

                    &:before {
                        border-width: 7.5rem 0 7.5rem 2.7rem;
                        border-color: transparent transparent transparent $white;
                        right: 0;
                    }
                }
            }
        }

        .mobile-content {
            h2 {
                color: $black;
                text-shadow: 0.1rem 0.1rem 0.2rem lighten($black, 30%);

                @include breakpoint(map-get($breakpoints, 'medium')) {
                    font-size: 5rem;
                }
            }

            .image {
                float: left;
                width: 30%;
                border-radius: 50%;
                border: 0.1rem solid $black;
                margin: 1rem;
                overflow: hidden;
                padding: 0.2rem;

                @include breakpoint(map-get($breakpoints, 'medium')) {
                    width: 20%;
                }

                @include breakpoint(map-get($breakpoints, 'large')) {
                    width: 10%;
                }

                .img-placeholder {
                    border-radius: 50%;
                }
            }

            .content {
                font-size: 1.4rem;
            }
        }
    }

    .orbit-bullets {
        &.desktop {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.mobile {
            .orbit-controls {
                .orbit-previous, .orbit-next {
                    cursor: pointer;
                    color: $black;
                    background: transparent;
                    border-radius: 0;
                    margin: 0;
                    padding: 0;
                    width: 2rem;
                    height: 2rem;
                }

                .orbit-previous {
                    left: map-get($grid-padding-gutters, 'small') / 2;
                }

                .orbit-next {
                    right: map-get($grid-padding-gutters, 'small') / 2;
                }
            }
        }
    }
}

.index-boxes {
    .grid-x {
        @include breakpoint(map-get($breakpoints, 'large')) {
            position: relative;

            &:before, &:last-child:after {
                display: block;
                content: " ";
                position: absolute;
                left: calc(50% - 1rem);
                z-index: 1;
                mix-blend-mode: multiply;
            }

            &:before {
                top: 0;
                height: 100%;
                width: 2rem;
                background-color: $marso-red;
            }

            &:last-child:after {
                bottom: -1rem;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 1rem 1rem 0 1rem;
                border-color: $marso-red transparent transparent transparent;
            }

            &.left-content {
                .cell {
                    h3, .content {
                        margin-left: auto;
                    }

                    &:first-child {
                        width: calc(50% - 1rem);
                    }
                    &:last-child {
                        width: calc(50% + 1rem);
                    }
                }
            }

            &.right-content {
                .cell {
                    h3, .content {
                        margin-right: auto;
                    }

                    &:first-child {
                        width: calc(50% + 1rem);
                    }
                    &:last-child {
                        width: calc(50% - 1rem);
                    }
                }
            }
        }

        .cell {
            h3 {
                color: $marso-red;
            }

            .content {
                a, a:hover {
                    color: $marso-red;
                }
            }

            h3, .content {
                max-width: $global-width / 2;
            }
        }

        &.left-content {
            .cell {
                &:first-child {
                    @include xy-gutters($gutter-type: padding, $gutter-position: top right bottom left);
                }
            }
        }

        &.right-content {
            .cell {
                &:last-child {
                    @include xy-gutters($gutter-type: padding, $gutter-position: top right bottom left);
                }
            }
        }
    }
}

.bottom-link {
    a.button {
        font-family: $header-font-family;
        @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);
        @include xy-gutters($gutter-type: padding, $gutter-position: top bottom);
        background-color: $marso-red;
        font-size: 2rem;
        border-radius: 0.5rem;

        &:hover {
            background-color: darken($marso-red, 10%);
        }

        @include breakpoint(map-get($breakpoints, 'large')) {
            font-size: 3rem;
            border-radius: 1rem;
        }
    }
}

#error-404-page
{
    .error-404-header-panel {
        background: transparent url(../img/error-404_car.png) no-repeat center 4rem;
        min-height: 50rem;
        padding-top: 30rem;

        .handwritten {
            font-weight: 400 !important;
        }

        h1 {
            color: #de3a3e;
            font-size: 9rem !important;
            line-height: 7rem;
            margin-bottom: 20px;
        }

        h2 {
            color: #de3a3e;
            font-size: 5rem;
        }
    }

    .ui.container {
        display: block;
        max-width: 100%!important;
    }

    @media only screen and (min-width: 1200px) {
        .ui.container {
            margin-left: auto!important;
            margin-right: auto!important;
            width: 1143px;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .ui.container {
            margin-left: auto!important;
            margin-right: auto!important;
            width: 945px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .ui.container {
            margin-left: auto!important;
            margin-right: auto!important;
            width: 731px;
        }
    }

    @media only screen and (max-width: 767px) {
        .ui.container {
            margin-left: 1em!important;
            margin-right: 1em!important;
            width: auto!important;
        }
    }

    .ui.grid {
        display: flex;
    }

    .info-block {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 0 0 3rem !important;
        padding: 1.5rem 0 !important;

        .text-center {
            text-align: center;
        }

        .column {
            align-self: center !important;
            flex-direction: column;
            vertical-align: middle;
            padding: 1rem;
        }

        @media only screen and (min-width: 992px) {
            .twoWide {
                width: 12.5% !important;
            }
            .fourteenWide {
                width: 87.5% !important;
            }
        }

        @media only screen and (max-width: 991px) {
            .fourteenWide {
                width: 100% !important;
            }
            .twoWide {
                display: none !important;
            }
        }

        .error-number {
            color: grey;
            font-size: 5rem;
            font-weight: 700;
        }
    }

    .menu-error-404 {
        padding-bottom: 6rem;

        .ui.grid {
            align-items: stretch;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: -1rem;
            padding: 0;
            padding-top: 1rem;
        }

        a {
            color: #4183c4;
            font-size: 16px;
        }

        h1 {
            color: #de3a3e;
            font-size: 4rem;
        }

        .fourWide {
            width: 25% !important;
            padding: 1rem;
        }

        @media only screen and (max-width: 991px) {
            .fourWide {
                width: 50% !important;
            }
        }

        .item {
            background-color: #f0f0f0;
            border-radius: 1rem;
            color: #de3a3e;
            display: block;
            min-height: 7rem;

            .text {
                color: #262626;
                display: block;
                font-size: 1.6rem;
                font-weight: 700;
                padding-top: 1.5rem;
                text-transform: uppercase !important;
                padding-right: 1em;
                padding-left: 1em;
            }
        }
    }
}
