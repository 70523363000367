.garages-page {
    .garages-list {
        .garage {
            a {
                position: relative;
                display: block;

                h2, .h2-bg {
                    position: absolute;
                    top: 50%;
                    transform: perspective(1px) translateY(-50%);
                    font-size: 3.4rem;
                    width: 100%;
                    @include xy-gutters($gutter-type: padding, $gutter-position: top bottom);
                }

                .h2-bg {
                    background-color: $marso-red;
                    mix-blend-mode: multiply;
                    display: block;
                }

                h2 {
                    color: $white;
                    text-align: center;
                    overflow: hidden;
                }
            }
        }
    }
}

.garage-page {
    .image-gallery {
        width: 100%;
        float: left;
        text-align: center;

        @include breakpoint(map-get($breakpoints, 'medium')) {
            max-width: 50%;
        }

        a {
            &:not(:first-child) {
                display: inline-block;
                width: 20%;
            }

            .img-placeholder {
                @include xy-gutters($gutter-type: margin, $gutter-position: top bottom left right);
            }
        }
    }

    h1, h2, h3, h4, h5 {
        color: $marso-red;
    }

    .employees-about-us-box {
        .employee-name, .employee-position {
            color: $marso-red;
            display: block;
        }

        .avatar {
            float: left;
            border-radius: 50%;
            width: 100%;
            max-width: 30%;
            border: 0.3rem solid $medium-gray;
            overflow: hidden;

            @include xy-gutters($gutter-type: margin, $gutter-position: top bottom left right);
        }

        .cell {
            @include breakpoint(map-get($breakpoints, 'medium')) {
                &:nth-child(even) {
                    @include xy-gutters($gutter-type: padding, $gutter-position: left);
                }

                &:nth-child(odd) {
                    @include xy-gutters($gutter-type: padding, $gutter-position: right);
                }
            }
        }
    }
}
