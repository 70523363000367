.news_listing, .news_record {
    h2, h3 {
        font-family: $body-font-family;
    }

    h2 {
        // font-size: 3rem;
    }

    h3 {
        // font-size: 2.5rem;
    }

    .card-divider.date, article.date {
        font-family: serif;
        font-weight: normal;
        font-size: 1.3rem;
        border-top: 2px solid $marso-red;
        border-bottom: 2px solid $marso-red;
    }
}

.news_record {
    padding-top: 1rem;

    .info-line {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    h2 {
        font-family: $body-font-family;
    }

    figure.image {
        border-bottom: 2px solid $marso-red;
        margin-bottom: 1rem;
    }

    .tags {
        .tag {
            font-size: 1.5rem;
            font-family: serif;
            font-weight: normal;
            text-transform: none;
            overflow-wrap: normal;
            word-break: keep-all;
            white-space: nowrap;
            color: #000;
            background-color: $tag-color;
            padding: 0.2rem 0.5rem;

            &::after {
                display: none;
            }

            &::before {
                content: "#";
                display: inline-block;
            }
        }
    }
}
