h1, h2, h3, h4, h5, h6 {
    color: $marso-red;
}

.tag {
    font-size: 1.5rem;
    text-transform: capitalize;
    overflow-wrap: normal;
    word-break: keep-all;
    white-space: nowrap;

    &::after {
        content: "⋅";
        margin: 0 1rem;
        display: inline-block;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.row-border-top {
    border-top: 0.2rem solid $medium-gray;
}

.row-border-bottom {
    border-bottom: 0.2rem solid $medium-gray;
}

.checkbox {
    cursor: pointer;
    float: left;
    line-height: #{$form-label-line-height}rem;
    position: relative;
    width: 18px;
    height: 18px;
    margin: 0 6px 0 0;
    top: 2px;

    .inline {
        &:last-child {
            margin-right: 0;
        }
    }

    + label {
        cursor: pointer;
    }

    input[type="checkbox"] {
        position: relative;
        opacity: 0;
        z-index: 1;
        width: 100%;
        height: 100%;

        &:checked + .custom-checkbox {
            border-color: $marso-red;
            box-shadow: none;

            .icon-check {
                transform: scale(1);
            }
        }
    }

    .custom-checkbox {
        display: inline-block;
        vertical-align: text-bottom;
        margin: 0 6px 0 0;
        width: 18px;
        height: 18px;
        border: 1px solid $marso-red;
        background: $marso-red;
        box-shadow: inset 0 0 0 12px #fff;
        position: absolute;
        left: 0;
        top: 0;
    }

    .icon-check {
        width: 20px;
        height: 20px;
        background-size: cover;
        position: absolute;
        top: 0px;
        left: 0px;
        transform: scale(0);
        transition: all 180ms ease;

        &:before {
            position: absolute;
            content: '\2713';
            width: 12px;
            font-size: 12.66667px;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-weight: bold;
        }
    }
}

input[type='checkbox'] {
    margin-top: -30px;
    text-rendering: optimizeSpeed;
    width: 14px;
    height: 14px;
    margin: 0 1px 0 0;
    display: block;
    float: left;
    position: relative;
    cursor: pointer;

    &:after {
        content:"";
        vertical-align: middle;
        text-align: center;
        line-height: 1.6rem;
        position: absolute;
        cursor: pointer;
        height: 1.6rem;
        width: 1.6rem;
        left: 0px;
        top: 0px;
        font-size: 10px;
        border: solid 1px #ccc;
        box-shadow: inset 0px 1px 4px rgba(0,0,0,0.1);
        background: #fff;
        font-size: 1.5rem;
    }

    &:hover:after {
        box-shadow: inset 0px 1px 4px rgba(0,0,0,0.2);
    }

    &:checked:after {
        content: "\2713";
        color: $marso-red;
        font-weight: bold;
    }
}

label {
    cursor: pointer;
    input[type='checkbox'] {
        margin-top: 4px;
        margin-right: 10px;
    }
}

.page {
    position: relative;

    #result-list.loading:after {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        content: " ";
        background-color: rgba(0, 0, 0, 0.5);
    }
}

button.marso-red, .button.marso-red {
    color: $white !important;
    background-color: $marso-red;
    font-size: 2rem;
    width: 100%;
    border-radius: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    position: relative;

    &:hover {
        color: $white !important;
        background-color: darken($marso-red, 10%);
    }

    .fas {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 3rem;
        padding: 1.2rem;
    }
}

.pagination {
    margin-top: $pagination-margin-bottom;

    .current {
        a, button {
            color: $pagination-item-color-current;
        }

        &:hover {
            background-color: $pagination-item-background-hover;
        }
    }

    li {
        a:hover, &:hover {
            color: $pagination-item-color-current;
        }
    }
}
