@import 'css/config';
//
@import '~motion-ui/src/motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;
@import '~foundation-sites/scss/foundation';
@include foundation-everything;
@import '~magnific-popup/src/css/main';
// @import '~@marso-ltd/cookie-consent/dist/cookie-consent.css';
@import '~vanilla-cookieconsent/src/cookieconsent.css';
//
@import 'css/placeholder';
//
@import 'css/overrides';
//
@import 'css/common';
@import 'css/main-menu';
@import 'css/index';
@import 'css/about_us';
@import 'css/garages';
@import 'css/positions'; // and employees-about-us
@import 'css/contact';
@import 'css/news';
