.contact_page {
    h2 {
        @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);
    }

    section {
        border-top: 0.2rem solid $medium-gray;
        @include xy-gutters($gutter-type: padding, $gutter-position: top bottom);
    }

    a, .marso-red {
        color: $marso-red;
    }

    .colleagues {
        .avatar {
            float: left;
            border-radius: 50%;
            width: 100%;
            max-width: 30%;
            overflow: hidden;

            @include xy-gutters($gutter-type: margin, $gutter-position: top bottom left right);

            .img-placeholder {
                border-radius: 50%;
            }
        }
    }

    .address {
        .socials {
            @include breakpoint(map-get($breakpoints, 'medium')) {
                text-align: right;
            }

            .social-icon {
                font-size: 4rem;

                &.facebook {
                    color: $facebook;
                    &:hover {
                        color: darken($facebook, 10%);
                    }
                }
                &.linkedin {
                    color: $linkedin;
                    &:hover {
                        color: darken($linkedin, 10%);
                    }
                }
                &.twitter {
                    color: $twitter;
                    &:hover {
                        color: darken($twitter, 10%);
                    }
                }
                &.instagram {
                    color: $instagrammagenta;
                    &:hover {
                        color: darken($instagrammagenta, 10%);
                    }
                }
                &.youtube {
                    color: $youtube;
                    &:hover {
                        color: darken($youtube, 10%);
                    }
                }
            }
        }
    }
}

.contact-form {
    form {
        // > .grid-x {
        //     @include xy-gutters($gutter-type: margin, $gutter-position: left right, $negative: true);
        // }

        label:not(.file-upload-button) {
            // text-transform: uppercase;
            font-weight: bold;

            &.required:after {
                content: "*";
                display: inline-block;
                vertical-align: middle;
            }
        }

        input {
            &[type="file"] {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;

                & + .file-upload-button {
                    @include form-element();

                    position: relative;
                    border-radius: 0.3rem;
                    border: 0.1rem solid $medium-gray;
                    cursor: pointer;

                    .file {
                        &.empty {
                            opacity: 0.2;
                        }
                    }

                    .upload-button {
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0.7rem;
                    }
                }
            }
        }

        textarea {
            min-height: 10rem;
            height: 12rem;
        }

        input, textarea, input[type="file"] + .file-upload-button {
            border-radius: 0.3rem;
            border: 0.1rem solid $medium-gray;
        }

        .submit-button-row {
            @include xy-gutters($gutter-type: margin, $gutter-position: left right, $negative: true);

            // button.marso-red {
            //     color: $white;
            //     background-color: $marso-red;
            //     font-size: 2rem;
            //     width: 100%;
            //     border-radius: 0.3rem;
            //     font-weight: bold;
            //     text-transform: uppercase;
            //     text-align: left;
            //     position: relative;

            //     .fas {
            //         position: absolute;
            //         right: 0;
            //         top: 0;
            //         font-size: 3rem;
            //         padding: 1.2rem;
            //     }
            // }
        }
    }

    .checkbox-cell {
        margin-bottom: $form-spacing;
    }

    @include breakpoint(map-get($breakpoints, 'large')) {
        .checkbox-cell {
            padding-top: #{$form-label-line-height}rem;
        }
    }

    // .column-2 {
    //     column-count: 1;
    //     column-gap: 0;

    //     @include xy-gutters($gutter-type: margin, $gutter-position: left right, $negative: true);

    //     .column-cell {
    //         position: relative;
    //         width: 100%;
    //         page-break-inside: avoid;
    //         break-inside: avoid-column;
    //         // display:table;
    //         @include xy-gutters($gutter-type: padding, $gutter-position: left right);
    //     }

    //     @include breakpoint(map-get($breakpoints, 'medium')) {
    //         column-count: 2;
    //     }
    // }
}
