.about_us_page {
    p {
        font-size: 1.5rem;
    }

    h2 {
        color: $marso-red;
        @include xy-gutters($gutter-type: margin, $gutter-position: top bottom);
    }
}
