body {
    font-size: $body-font-size;
}
nav.white-top {
    font-size: 1em;
}
.text-uppercase {
    text-transform: uppercase;
}

/**
 * Cookie consent overrides
 */

$marso-brand-blue: #3f51b5;
$blue: $marso-brand-blue;
$marso-blue: $marso-brand-blue;
$marso-light-blue: #8c97d3;

$marso-gray-20: #cccccc;
$marso-gray-30: #b2b2b2;

$blueTextColor: $white;

$textShadow: none;
$invertedTextShadow: $textShadow;
$blueTextShadow: $invertedTextShadow;

$blueHover: saturate(darken($blue, 5), 10);

body {
    button#cookie-consent-showButton {
        background-image: url('../img/cookies.svg') !important;
    }

    div#cookie-consent-wrapper {
        .cookie-consent-modal {
            font-family: $body-font-family !important;
            padding: 4rem 2rem !important;

            .cookie-consent-modal-inner {
                max-width: 900px !important;
                width: 100%;
                font-size: 1.5rem !important;

                .cookie-consent-modal-cookie-image {
                    background-image: url('../img/cookies.svg') !important;
                }

                @media screen and (max-width: 512px) {
                    .cookie-consent-modal-cookie-image {
                        background-image: none;
                        width: 0;
                        height: 0;
                        margin: 0;
                    }
                }

                .cookie-consent-modal-row {
                    align-items: center;


                    .cookie-consent-modal-title {
                        font-size: 4rem !important;
                        line-height: 4rem;
                        text-transform: none !important;
                    }
                }

                button {
                    border-color: $marso-light-blue !important;
                    text-shadow: none !important;

                    &:not(.outline) {
                        background-color: $blue !important;
                        color: $blueTextColor !important;
                        text-shadow: $blueTextShadow !important;
                    }

                    &.outline {
                        border-color: $marso-gray-20 !important;
                        color: $marso-gray-30 !important;

                        &:hover {
                            border-color: $marso-light-blue !important;
                            color: $blueTextColor !important;
                        }
                    }

                    &:hover {
                        background-color: $blueHover !important;
                        color: $blueTextColor !important;
                        text-shadow: $blueTextShadow !important;
                    }
                }

                .cookie-consent-modal-category-switch {
                    .cookie-consent-modal-category-switch-slider {
                        background-color: $marso-gray-30 !important;
                    }

                    input:checked + .cookie-consent-modal-category-switch-slider {
                        background-color: $blue !important;
                        color: $blueTextColor !important;
                    }
                }
            }

            .cookie-consent-modal-inner, .cookie-consent-modal-accept-button-wrapper button {
                font-size: 1.8rem !important;
            }
        }
    }
}